import React, { useState } from "react";

interface LoginFormProps {
  onSubmit: (name: string, mobile: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [errors, setErrors] = useState<{ name?: string; mobile?: string }>({});
  const [submitting, setSubmitting] = useState<boolean>(false);

  const validateForm = (): boolean => {
    const newErrors: { name?: string; mobile?: string } = {};

    if (!name.trim()) {
      newErrors.name = "Name is required.";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters.";
    }

    if (!mobile.trim()) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits.";
    }

    setSubmitting(false);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    if (validateForm()) {
      onSubmit(name, mobile);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
  };

  return (
    <div className="flex items-center justify-center w-full">
      <form
        onSubmit={handleSubmit}
        className="p-3 rounded-lg shadow-md text-primary-50 animate-fadeIn w-full"
      >
        <h1 className="text-3xl font-bold mb-6 text-center w-full">Login</h1>

        <div className="mb-4 w-full">
          <label htmlFor="name" className="block mb-2 text-primary-100">
            Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Your Name"
            value={name}
            onChange={(e) => {
              const capitalizeWords = (str: string) => str.replace(/\b\w/g, char => char.toUpperCase());
              setName(capitalizeWords(e.target.value));
            }}
            className={`w-full px-4 py-2 rounded-md bg-primary-800 border ${errors.name ? "border-red-500" : "border-primary-700"
              } focus:outline-none focus:ring-2 focus:ring-primary-400`}
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div className="mb-4 w-full">
          <label htmlFor="mobile" className="block mb-2 text-primary-100">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobile"
            placeholder="Your Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className={`w-full px-4 py-2 rounded-md bg-primary-800 border ${errors.mobile ? "border-red-500" : "border-primary-700"
              } focus:outline-none focus:ring-2 focus:ring-primary-400`}
          />
          {errors.mobile && (
            <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full mt-4 px-6 py-2 text-primary-900 bg-primary-50 rounded-lg shadow hover:bg-primary-300 hover:text-primary-600 transition-all duration-300"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;