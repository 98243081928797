import React from "react";

const translations = [
  "क्या आप कौन बनेगा CPA शुरू करने के लिए तैयार हैं?",
  "আপনি কি কোন হবে CPA শুরু করতে প্রস্তুত?",
  "நீங்கள் கௌன் बनेगा CPA தொடங்க தயாரா?",
  "మీరు కౌన్ बनेगा CPA ప్రారంభించడానికి సిద్ధంగా ఉన్నారా?",
  "ನೀವು ಕೌನ್ बनेगा CPA ಪ್ರಾರಂಭಿಸಲು ಸಿದ್ಧವೇ?",
  "നിങ്ങൾ കൗന് बनेगा CPA ആരംഭിക്കാൻ തയ്യാറാണോ?",
  "ਕੀ ਤੁਸੀਂ ਕੌਨ बनेगा CPA ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਤਿਆਰ ਹੋ?",
  "તમે કૌન बनेगा CPA શરૂ કરવા માટે તૈયાર છો?",
  "आपण कौन बनेगा CPA सुरू करण्यास तयार आहात का?",
  "کیا آپ کون بنے گا CPA شروع کرنے کے لیے تیار ہیں؟",
  "ଆପଣ କଉନ୍ बनेगा CPA ଆରମ୍ଭ କରିବାକୁ ପ୍ରସ୍ତୁତ କି?",
  "আপুনি কৌন बनेगा CPA আৰম্ভ কৰিবলৈ সাজু নেকি?",
  "ڇا توهان ڪون बनेگا CPA شروع ڪرڻ لاءِ تيار آهيو؟",
  "چھُ تہٕ کون बनेگا CPA شروع کرنہ گاسِ تیار؟",
];

const AboutToStart = () => {
  return (
    <div className="relative flex flex-col items-center justify-center h-screen overflow-hidden">
      {/* Background animated text */}
      <div className="absolute inset-0 flex flex-wrap">
        {translations.map((text, index) => (
          <p
            key={index}
            className="text-primary-50 text-xl font-bold opacity-10 m-4 animate-fadeIn tracking-wide"
            style={{
              animationDelay: `${(index % translations.length) * 2}s`, // Staggered animation for effect
            }}
          >
            {text}
          </p>
        ))}
      </div>

      {/* Foreground content */}
      <div className="relative z-10 text-center">
        <h1 className="text-primary-50 text-5xl font-bold mb-6 animate-fadeInDown">
          KB CPA
        </h1>
        <p className="text-primary-100 text-xl mb-12 text-center animate-fadeIn">
          Are you ready to start the <q><strong>Koun Banega CPA</strong></q>?  
          <br />
          <br />
          Take a deep breath and let's begin!
        </p>
      </div>
    </div>
  );
};

export default AboutToStart;