import { FC } from "react";
import { FaAward } from "react-icons/fa";

const ResultComponent: FC<{
  quizzData: any;
}> = ({
  quizzData
}) => {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center text-center animate-fadeIn p-8 rounded-lg bg-primary-500 shadow-md">
          <FaAward className="text-primary-50 text-6xl mb-4 animate-bounce" />
          <h1 className="text-primary-50 text-4xl font-bold">Congratulations!</h1>
          <p className="text-primary-100 mt-2 text-xl">
            You have successfully completed the Poll: <span className="font-semibold text-primary-50">{quizzData?.exam_title}</span>
          </p>
          {/* <button
            className="mt-6 px-6 py-2 text-primary-900 bg-primary-50 rounded-lg shadow hover:bg-primary-200 hover:text-primary-600 transition-all duration-300"
          >
            Go to Dashboard
          </button> */}
        </div>
      </div>
    )
  }

export default ResultComponent;