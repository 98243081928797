import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { miles_quizz_db } from "../services/SupabaseClientInstance";
import { errorAlert } from "../lib/alerts";
import { setUserData } from "../lib/user";
import LoginForm from "../Components/pages/auth/Login";

const Authentication = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const quizzId = searchParams.get('qi');
  const [authLoading, setAuthLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const checkUserDetails = async (userId: string) => {
    setAuthLoading(true);
    try {
      const { data, error } = await miles_quizz_db.rpc('user_auth_check', { userid: userId });
      if (error) {
        throw new Error(error.message);
      } else {
        if (data) {
          setUserData(data[0]);
          if (data[0]?.exam_active_status) {
            navigate('/quizz');
          } else {
            navigate('/no-quizz');
          }
        }
      }
    } catch (error: any) {
      errorAlert("An error occurred while authenticating user");
    } finally {
      setAuthLoading(false);
    }
  };

  const checkTheLoginStatus = () => {
    // const data = getUserData();
    // if (data?.quizz_id === quizzId) {
    //   navigate('/quizz');
    // } else {
    //   setShowLogin(true);
    // }
    setShowLogin(true);
  }

  const checkQuizzData = async () => {

    try {
      const todayDateInIndia = new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }).split(',')[0].split('/').reverse().join('-');
      const { data, error } = await miles_quizz_db
        .from('quizz_list')
        .select('quizz_current_question')
        .eq('id', quizzId)
        .eq('exam_status', true)
        .eq('start_date', todayDateInIndia);
      if (error) {
        throw new Error(error.message);
      } else {
        if (data && data.length > 0) {
          checkTheLoginStatus();
        } else {
          navigate('/no-quizz');
        }
      }
    } catch (error: any) {
      errorAlert("An error occurred while authenticating user");
    } finally {
      setAuthLoading(false);
    }
  };

  const createUser = async (name: string, mobile: string) => {
    try {
      const { data, error }: {
        data: any,
        error: any
      } = await miles_quizz_db
        .from('quizz_users')
        .insert([
          {
            user_name: name,
            user_phone: mobile,
            quizz_id: quizzId
          }
        ]).select('auto-random-id');
      if (error) {
        throw new Error(error.message);
      } else {
        if (data) {
          console.log("User created", data);
          checkUserDetails(data[0]['auto-random-id']);
        }
      }
    } catch (error: any) {
      errorAlert("An error occurred while authenticating user");
    } finally {
      setAuthLoading(false);
    }
  }

  const checkMobileNumber = async (name: string, mobile: string) => {
    try {
      const { data, error }: {
        data: any,
        error: any
      } = await miles_quizz_db
        .from('quizz_users')
        .select('auto-random-id')
        .eq('user_phone', mobile)
      if (error) {
        throw new Error(error.message);
      } else {
        if (data && data.length > 0) {
          setUserData(data[0]);
          // navigate('/quizz');
          checkUserDetails(data[0]['auto-random-id']);
        } else {
          createUser(name, mobile);
        }
      }
    } catch (error: any) {
      errorAlert("An error occurred while authenticating user");
    } finally {
      setAuthLoading(false);
    }
  };

  const onSubmit = (name: string, mobile: string) => {
    checkMobileNumber(name, mobile);
  }

  useEffect(() => {
    if (quizzId) {
      checkQuizzData();
    }
  }, [quizzId]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {authLoading ? <>
        <div className="flex items-center space-x-2">
          <div className="animate-bounce bg-blue-500 rounded-full h-4 w-4"></div>
          <div className="animate-bounce bg-blue-500 rounded-full h-4 w-4 animation-delay-200"></div>
          <div className="animate-bounce bg-blue-500 rounded-full h-4 w-4 animation-delay-400"></div>
        </div>
        <h1 className="mt-4 text-xl font-semibold text-primary-100">
          Authenticating, please wait...
        </h1>
      </> : <>
        {
          showLogin ? <LoginForm onSubmit={onSubmit} /> : <h1 className="mt-4 text-xl font-semibold text-primary-100">
            An error occurred while authenticating user
          </h1>
        }
      </>}
    </div>
  )
}

export default Authentication;