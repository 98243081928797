import { FC } from "react";
import { Alphabates } from "./QuestionsContainer";

const Option: FC<{
  option: string;
  tag: string;
  selected: string;
  setSelectedOption: (option: string) => void;
  revelData: {
    correct_option: number | null,
    isReveled: boolean
  }
}> = ({
  option,
  tag,
  selected,
  setSelectedOption,
  revelData: {
    correct_option = null,
    isReveled = false
  } }) => {
    return (
      <div
        onClick={() => {
          if (selected === "" && !isReveled) {
            setSelectedOption(tag);
          }
        }}
        className={`p-2 rounded-md text-sm  
    ${(isReveled === false && selected === "") ? "bg-gradient-to-br from-primary-500 to-primary-500" : ""}
    ${(isReveled === false && tag === selected) ? "bg-primary-400" : ""}
    ${(isReveled && correct_option!==null && Alphabates[correct_option] === tag) ? "bg-green-500" : ""}
    ${(isReveled && correct_option!==null && selected === tag && Alphabates[correct_option] !== tag) ? "bg-red-600" : ""}
    ${selected !== "" ? "pointer-events-none cursor-none" : "cursor-pointer pointer-events-auto hover:bg-primary-300"}
  `}
      >
        <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-primary-200 rounded-full mr-2">
          {tag}
        </span>
        {option}
      </div>
    );
  };

export default Option;